// New

// https://github.uio.no/IT-VORTEX/it-vortex-annet/issues/95
body.uio-test\.uio\.no,
body.www\.uio\.no {
  .vrtx-breadcrumb-level:has(a[href$="/programme-options/"]),
  .vrtx-breadcrumb-level:has(a[href$="/studieretninger/"]) {
    display: none !important;
  }
  .vrtx-context-box.vrtx-context-box-linked {
    margin-top: 10px;

    a {
      text-decoration: none;
    }

    @media @responsiveBreakpoint {
      display: none;
    }
  }
}
#vrtx-fs-studieretning-frontpage {
  .vrtx-context-box {
    display: none;
  }

  // Fix because .vrtx-breadcrumb-before-active / studieretninger is hidden
  #breadcrumbs .vrtx-breadcrumb-level-2 {
    margin-bottom: 40px;
  }

  &:has(.program-context) h1 {
    margin-bottom: 10px;
  }

  .program-context {
    .mixin-h4;
    margin-bottom: 40px;
  }
}

#vrtx-fs-studieprogram-frontpage,
#vrtx-fs-studieretning-frontpage {
  &:not(.sidebar-menu-wrapper-visible) #head-wrapper,
  #breadcrumbs,
  #vrtx-fs-studieprogram-contact,
  #vrtx-fs-studieprogram-title-intro-links-wrapper,
  #vrtx-fs-studieretning-title-intro-links-wrapper,
  #vrtx-fs-studieprogram-content ul.navigation-links li a {
    background-color: #CEFFDF;
  }

  #vrtx-fs-studieprogram-links ul.navigation-links li a {
    background-color: #fff;
  }

  #main .vrtx-context-message-box.uio-info-message.blue {
    background-color: #CEFFDF;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 20px;

    .row {
      background: var(--infoBoxDefaultColor);
    }

    .content-wrapper {
      background: transparent url(/vrtx/dist/resources/uio2/css/images/banner/info.svg) no-repeat 220px 20px;
      padding-top: 25px;
      padding-bottom: 25px;

      @media @desktopSmallBreakpoint {
        background-position: 25px 20px;
      }
      @media @responsiveBreakpoint {
        background-position: 15px 20px;
      }

      .message-text {
        margin-left: 60px;
      }
    }
  }

  #main #breadcrumbs {
    margin-top: -60px;
    padding-top: 60px;

    @media @responsiveBreakpoint {
      margin-top: -40px;
      padding-top: 40px;
      z-index: 1;
      position: relative;
    }
  }

  #vrtx-program-links {
    margin-bottom: 0;
  }

  #vrtx-fs-studieprogram-contact {
    padding: 50px 40px 60px 40px;

    @media @responsiveBreakpoint {
      padding: 40px 20px 50px 20px;
    }
  }

  #vrtx-fs-studieretning-content,
  #vrtx-fs-studieprogram-content {
    margin-top: 50px;

    > * {
      margin-bottom: 100px;

      &:last-child {
        margin-bottom: 0;
      }
    }
    > * > *:first-child {
      margin-top: 0;
    }
    > * > *:last-child {
      margin-bottom: 0;
    }

    #vrtx-fs-studieprogram-contact .info >*:last-child {
      margin-bottom: 0;
    }
  }

  .vrtx-article-body,
  #vrtx-fs-studieretning-list .accordion-wrapper,
  #vrtx-fs-studieprogram-contact {
    max-width: 1000px;
  }

  .vrtx-introduction {
    margin-top: 0;
    margin-bottom: 40px;
  }

  .vrtx-introduction-image {
    display: none;
  }

  #vrtx-fs-studieprogram-contact + #vrtx-fs-studieprogram-delivered-by {
    margin-top: -50px;
  }

  ul.navigation-links li {
    margin-bottom: 20px;
  }
}

#vrtx-fs-studieprogram-frontpage {
  #vrtx-fs-studieprogram-facts {
    margin-top: -30px;
    margin-bottom: 40px;

    @media @responsiveBreakpoint {
      margin-top: -10px;
    }

    dd, dt {
      margin: 0;
    }

    dl {
      display: flex;
      gap: 0 40px;

      @media @responsiveBreakpoint {
        gap: 10px 0;
        flex-direction: column;
      }
    }
    dt {
      display: block;
      .mixin-tiny;
      font-weight: normal;
      color: var(--colorNeutralTint20);
    }
    dd {
      .mixin-h4;
    }
  }

  #vrtx-fs-studieprogram-delivered-by {
    h2 {
      .mixin-h5;
      margin-bottom: 10px;
    }
  }
}

// Old

#vrtx-program-frontpage h1 {
  margin-bottom: 80px;
}

body {
  &#vrtx-program-frontpage,
  &#vrtx-program-option {
    h2 a {
      text-decoration-color: transparent;
      &:hover,
      &:focus {
        .textUnderline(@textColor);
      }
    }
    .vrtx-introduction {
      margin-bottom: 60px;
      margin-top: 8px;
    }
    #main.main ul.navigation-links {
      margin-bottom: 0;
      row-gap: 30px;
      li { 
        margin-bottom: 0;
      }
    }
    #vrtx-program-links ul {
      display: flex;
      flex-direction: column;
      row-gap: 20px;

      li {
        margin-bottom: 0;
      }
    }
  }
}

#vrtx-program-frontpage,
#vrtx-program-option {
  .vrtx-introduction {
    margin-bottom: 60px;
  }
  .vrtx-frontpage-box {
    position: relative;

    .vrtx-box-content {
      .firstLastChildNoMargin();
    }
  }
  .vrtx-program-resource-links {
    .left,
    .right {
      .firstLastChildNoMargin();
    }
  }
  .vrtx-introduction-image {
    display: none;
  }
}

#vrtx-program-frontpage-main,
#vrtx-program-option-main {
  margin-bottom: 0;
}

#vrtx-program-frontpage,
#vrtx-program-option {
  .vrtx-introduction-image {
    margin-bottom: 80px;
    float: none;
    width: auto !important;
    max-width: @h1IntroWidth !important;
    img {
      width: 100% !important;
    }
  }
  .vrtx-introduction-image {
    margin-left: 0;
  }
}

#vrtx-program-links {
  width: @h1IntroWidth;
  max-width: 100%;
  margin-bottom: 80px;
  padding: 50px;
  background: @green;
  &:after {
    .clearfix();
  }
}

#vrtx-program-links,
#vrtx-program .vrtx-program-options {
  ul {
    &,
    &:last-child {
      margin-bottom: 0;
    }
  }
}

#vrtx-program-option .vrtx-program-title-box {
  h3 {
    .baseFontSize;
    position: relative;
  }
}

#main #vrtx-main-content .vrtx-program-contact-box {
  .vrtx-more {
    position: static;
    margin-top: 15px;
  }
}

#vrtx-program-frontpage .vrtx-frontpage-box {
  ul.only-links a {
    text-decoration: none;
    &:hover,
    &:focus {
      .textUnderline(@textColor);
    }
  }
}

#vrtx-program-facts {
  max-width: @h1IntroWidth;
  width: auto;
  margin-top: 0;
  margin-bottom: 15px;
  padding: 8px 14px 7px 14px;
  border: solid 1px @borderColor;
  display: inline-block;
  font-size: 1.6rem;
  line-height: 2.9rem;
  letter-spacing: 0.6px;
  display: inline-block;
  th,
  td,
  tr {
    padding: 0;
    border: none;
    display: inline-block;
  }
  caption {
    display: none;
  }
  th {
    font-weight: bold;
    width: auto; // override table.vertical
  }
  td {
    margin-left: .25em;
    margin-right: 15px;

    &,
    strong {
      font-weight: normal;
    }
  }
}

body#vrtx-program-frontpage #vrtx-program-facts {
  max-width: @h1IntroWidth;
  margin-bottom: 15px;
  padding: 8px 14px 7px 14px;
  border: solid 1px @borderColor;
  display: inline-block;
  // Clear inline block
  clear: both;
  h2 {
    display: none;
  }

  *,
  h3 {
    font-size: 1.6rem;
    line-height: 2.9rem;
    letter-spacing: 0.6px;
    display: inline-block;
    margin: 0;
  }

  h3,
  dt {
    font-weight: bold;
  }
  p,
  dd {
    margin-left: .25em;
    margin-right: 15px;
    &,
    strong {
      font-weight: normal;
    }
  }
}

// Program option
#vrtx-program-frontpage,
#vrtx-program-option-main {
  .vrtx-introduction-image {
    width: auto !important;
    float: none;
    margin-left: 0;
  }
}

.vrtx-program-title-box {
  h3 {
    margin-top: 0;
  }
}

.vrtx-program-resource-links {
  width: var(--width);
  margin-left: ~"calc(calc(calc(calc(var(--width) - calc(100%)) / 2) + 100px) * -1)";
  background: @boxGreyBg;
  padding-top: 80px;
  padding-bottom: 80px;

  .vrtx-program-resource-links-inner {
    max-width: @gridWidth;
    min-width: @gridMinWidth;
    padding-right: @contentGapRight;
    padding-left: @sidebarWidth;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    &:after {
      .clearfix();
    }
  }
}

body {
  #main .vrtx-program-options li a:not(.button):not(.button-large) {
    padding-left: 20px;
    display: block;
    &:before {
      display: none;
    }
  }
}

@media @desktopSmallOnlyBreakpoint {
  .vrtx-program-resource-links {
    margin-left: -25px;

    .vrtx-program-resource-links-inner {
      min-width: @responsiveBreakpointPx;
      padding-left: 25px;
    }
  }
}

@media @responsiveBreakpoint {

  // Make fullwidth in responsive
  #vrtx-program-links {
    padding: 30px 15px;
    margin-left: -15px;
    margin-right: -15px;
    width: ~"calc(100% + 30px)";
    max-width: none;
  }
  #vrtx-program-facts {
    padding: 5px 10px;
    font-size: 1.3rem;
    line-height: 2.3rem;
  }
  .vrtx-program-resource-links {
    width: auto;
    padding: 30px 0;
    margin-left: -15px;
    margin-right: -15px;
    width: ~"calc(100% + 30px)" !important;

    .vrtx-program-resource-links-inner {
      max-width: none;
      min-width: 0;
      padding-right: 15px;
      padding-left: 15px;
      margin-left: 0;
      margin-right: 0;
    }
  }
}
