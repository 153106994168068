.hidden {
  display: none;
}

html {
  height: 100%; // puzzel chat needs this
}

// Header, footer, etc
.info-link-wrapper .info-link, // cookie banner
.uio-app-line-top, // not in use in new design at the moment
#head-wrapper #head,
#head-admin-menu-wrapper #head-admin-menu,
#marketing-customize-container .row,
#marketing-container .row,
.grid-container.grid-container-top .row,
.grid-container.uio-info-message.alert .row, // alert banner
#footer-wrapper #footers {
  max-width: @gridWidth;
  min-width: @gridMinWidth;
  margin: 0 auto;

  padding-left: @contentGapLeft;
  padding-right: @contentGapRight;
}

// Content
#main {
  max-width: @gridWidth;
  min-width: @gridMinWidth;
  padding-left: @contentGapLeft;
  padding-right: @contentGapRight;
  margin: 0 auto 105px auto;
  position: relative;

  &:after {
    .clearfix();
  }

  #left-main {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  #total-main,
  #right-main {
    padding-left: ~"calc(@{sidebarWidth} - @{contentGapLeft})";
    max-width: ~"calc(@{contentWidth} + (@{sidebarWidth} - @{contentGapLeft}))";
    min-width: ~"calc(@{contentMinWidth} + (@{sidebarWidth} - @{contentGapLeft}))";
  }
}

// Frontpage content

#vrtx-fs-studieprogram-frontpage #main,
#vrtx-fs-studieretning-frontpage #main {
  max-width: none;
  min-width: 0;
  padding-left: 0;
  padding-right: 0;

  #right-main,
  #total-main {
    padding-left: 0;
    max-width: none;
    min-width: 0;
  }

  .vrtx-context-message-box {
    background-image: none;
    padding-left: 0;
    padding-right: 0;
  }

  #vrtx-main-content > div:not(#vrtx-fs-studieprogram-title-intro-links-wrapper):not(#vrtx-fs-studieretning-title-intro-links-wrapper),
  .vrtx-context-message-box .content-wrapper,
  #vrtx-fs-studieprogram-title-intro-links-wrapper > *,
  #vrtx-fs-studieretning-title-intro-links-wrapper > *,
  #vrtx-breadcrumb-wrapper {
    max-width: @gridWidth;
    min-width: @gridMinWidth;
    padding-right: @contentGapRight;
    padding-left: @sidebarWidth;
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }
}

/* 100vw includes scrollbar so JS is used to set a CSS-variable with correct value
   Credits (uses the raw pixel value instead): https://stackoverflow.com/questions/23367345/100vw-causing-horizontal-overflow-but-only-if-more-than-one#answer-56254633
*/

#vrtx-frontpage.total-main #main {
  h1 {
    margin-left: 200px;
  }

  max-width: none;
  min-width: 0;
  padding-left: 0;
  padding-right: 0;

  #right-main,
  #total-main {
    padding-left: 0;
    max-width: none;
    min-width: 0;
  }

  #left-main {
    // browser or less removes 0px and causes max() function to fail, so use tiny value
    // if grid width is less than @gridWidth, sidebar is set to zero left
    left: ~"max(0.01px, calc(calc(calc(var(--width) - @{gridWidth}) / 2)))";
  }

  .row,
  .vrtx-back,
  .vrtx-context-box,
  h1,
  #breadcrumbs,
  #vrtx-frontpage-introduction {
    max-width: @gridWidth;
    min-width: @gridMinWidth;
    padding-right: @contentGapRight;
    padding-left: @sidebarWidth;
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }

  .row {
    padding-left: @sidebarWidth;
  }

  .grid-container {
    width: var(--width);
    margin-left: ~"calc(calc(calc(var(--width) - calc(100%)) / 2) * -1)";
  }
}

// 690px inner content, article, event etc

body:not(#vrtx-frontpage):not(#vrtx-event-listing):not(#vrtx-article-listing):not(#vrtx-searchview):not(#vrtx-unitsearch):not(#vrtx-personsearch):not(#vrtx-personal-data):not(#vrtx-helseforsk):not(#vrtx-semester):not(#vrtx-program-frontpage):not(#vrtx-program-option):not(#vrtx-structured-project):not(#vrtx-structured-project-two):not(#vrtx-person):not(#vrtx-course-description):not(#vrtx-student-exchange-agreement):not(#vrtx-organizational-unit):not(#vrtx-unit-person-listing):not(#vrtx-unit):not(#vrtx-contact-supervisor):not(#vrtx-fs-studieprogram-frontpage):not(#vrtx-fs-studieretning-frontpage) {
  #vrtx-main-content,
  #vrtx-main-user {
    > blockquote.blockquote-small,
    > *:not(h1):not(#vrtx-introduction-wrapper):not(.vrtx-introduction):not(.vrtx-introduction-image):not(#vrtx-main-user):not(blockquote):not(.vrtx-comments):not(.info-fullwidth-wide):not(.vrtx-byline) {
      max-width: @bodytextContentWidth;
      margin-right: auto;
      position: relative;
    }
    // Fix strange bug on old protokoll/sakskart
    > table.align-left:not(h1):not(#vrtx-introduction-wrapper):not(.vrtx-introduction):not(.small-intro-image):not(#vrtx-main-user):not(blockquote):not(.vrtx-comments):not(.info-fullwidth-wide):not(.vrtx-byline),
    > table.align-right:not(h1):not(#vrtx-introduction-wrapper):not(.vrtx-introduction):not(.small-intro-image):not(#vrtx-main-user):not(blockquote):not(.vrtx-comments):not(.info-fullwidth-wide):not(.vrtx-byline) {
      float: none;
    }
  }
  // When something floats left/right of list this is needed
  #vrtx-main-user {
    ul:not(.vrtx-social-list) {
      overflow: hidden;
    }
  }
}

@media only screen and (min-width: 1180px) {
  body:not(#vrtx-frontpage):not(#vrtx-event-listing):not(#vrtx-article-listing):not(#vrtx-searchview):not(#vrtx-unitsearch):not(#vrtx-personsearch):not(#vrtx-personal-data):not(#vrtx-helseforsk):not(#vrtx-semester):not(#vrtx-program-frontpage):not(#vrtx-program-option):not(#vrtx-structured-project):not(#vrtx-structured-project-two):not(#vrtx-person):not(#vrtx-course-description):not(#vrtx-student-exchange-agreement):not(#vrtx-organizational-unit):not(#vrtx-unit-person-listing):not(#vrtx-unit):not(#vrtx-contact-supervisor):not(#vrtx-fs-studieprogram-frontpage):not(#vrtx-fs-studieretning-frontpage) {
    #vrtx-main-content,
    #vrtx-main-user {
      > blockquote.blockquote-small,
      .vrtx-introduction-image.medium-intro-image, // Center <=690px intro image
      > *:not(h1):not(#vrtx-introduction-wrapper):not(.vrtx-introduction):not(.vrtx-introduction-image):not(#vrtx-main-user):not(blockquote):not(.vrtx-comments):not(.info-fullwidth-wide):not(.vrtx-byline):not(.blog-title) {
        margin-left: ~"calc(calc(calc(100%) - @{bodytextContentWidth} - calc(calc(100%) - @{h1IntroWidth}))/2)";
      }
      // Fix for idents not set on sub-lists, typical paragraphs
      >p[style*='margin-left: 40px']  { padding-left: 40px;  }
      >p[style*='margin-left: 80px']  { padding-left: 80px;  }
      >p[style*='margin-left: 120px'] { padding-left: 120px; }
      >p[style*='margin-left: 40px'],
      >p[style*='margin-left: 80px'],
      >p[style*='margin-left: 120px'] {
        margin-left: ~"calc(calc(calc(100%) - @{bodytextContentWidth} - calc(calc(100%) - @{h1IntroWidth}))/2)" !important;
      }
    }
  }
}

// Some part of course description and semester shall be narrower and centered
body#vrtx-course-description #vrtx-course-content,
body#vrtx-course-description .vrtx-date-info,
body#vrtx-semester #vrtx-main-content .vrtx-frontpage-box:not(:first-child):not(#vrtx-semester-resource-links),
body#vrtx-student-exchange-agreement #vrtx-main-content > *:not(#vrtx-student-exchange-agreement-contact):not(#vrtx-student-exchange-agreement-university-info):not(.vrtx-introduction):not(#vrtx-student-exchange-agreement-info):not(h1) {
  max-width: @bodytextContentWidth;
  margin-left: ~"calc(calc(calc(100%) - @{bodytextContentWidth} - calc(calc(100%) - @{h1IntroWidth}))/2)";
  margin-right: auto;
  position: relative;
}

body:not(#vrtx-frontpage):not(#vrtx-course-description):not(#vrtx-semester):not(#vrtx-program-frontpage):not(#vrtx-program-option):not(#vrtx-person):not(#vrtx-unit-person-listing):not(#vrtx-organizational-unit):not(#vrtx-unit):not(#vrtx-fs-studieprogram-frontpage):not(#vrtx-fs-studieretning-frontpage) {
  .vrtx-frontpage-box {
    h1 {
      margin-left: -@sidebarWidth;
    }
  }
}
body:not(#vrtx-frontpage):not(#vrtx-searchview):not(#vrtx-unitsearch):not(#vrtx-personsearch):not(#vrtx-course-description):not(#vrtx-semester):not(#vrtx-personal-data):not(#vrtx-helseforsk):not(#vrtx-program-frontpage):not(#vrtx-program-option):not(#vrtx-structured-project):not(#vrtx-person):not(#vrtx-organizational-unit):not(#vrtx-unit) {
  #vrtx-additional-content {
    .vrtx-frontpage-box {
      margin-left: @sidebarWidth;
      margin-top: 40px;
    }
  }
}

// Content still with right column

#vrtx-unitsearch,
#vrtx-searchview,
#vrtx-personsearch,
#vrtx-personal-data,
#vrtx-helseforsk,
#vrtx-event-listing,
#vrtx-article-listing,
#vrtx-structured-project,
#vrtx-organizational-unit,
#vrtx-person {
  #main {
    &:after {
      .clearfix();
    }
  }
  #vrtx-calendar-listing,
  #vrtx-content {
    #vrtx-main-content {
      width: 68.333333%; // max 690px
      padding-right: 130px;
      float: left;

      &:only-child {
        width: 100%;
        float: none;
        padding-right: 0;
      }
    }
    #vrtx-additional-content {
      width: 31.666666%; // max 380px
      float: left;

      >* {
        margin-bottom: 40px;
      }

      #vrtx-related-content {
        h2 {
          margin-top: 40px;
        }
      }
    }
  }
  .vrtx-introduction-image:not(.small-intro-image) {
    margin-left: 0;
  }
  .vrtx-introduction + .vrtx-introduction-image {
    margin-top: -10px;
  }
}

#vrtx-event-listing.datepicker-only-right-column {
  #vrtx-calendar-listing,
  #vrtx-content {
    #vrtx-main-content {
      width: 75%;
      padding-right: 0;
    }
    #vrtx-additional-content {
      width: 25%;
    }
  }
}

#vrtx-program-option-frontpage-change-language-link,
#vrtx-change-language-link,
.vrtx-change-language-link {
  float: right;
  margin: 0 0 40px 40px;
}

// not in use in new design
#uio-app-menu-wrapper {
  display: none;
}

@media @desktopSmallOnlyBreakpoint {
  body:not(#vrtx-frontpage):not(#vrtx-event-listing):not(#vrtx-article-listing):not(#vrtx-searchview):not(#vrtx-unitsearch):not(#vrtx-personsearch):not(#vrtx-personal-data):not(#vrtx-helseforsk):not(#vrtx-semester):not(#vrtx-program-frontpage):not(#vrtx-program-option):not(#vrtx-structured-project):not(#vrtx-structured-project-two):not(#vrtx-person):not(#vrtx-course-description):not(#vrtx-student-exchange-agreement):not(#vrtx-organizational-unit):not(#vrtx-unit-person-listing):not(#vrtx-unit):not(#vrtx-contact-supervisor):not(#vrtx-fs-studieprogram-frontpage):not(#vrtx-fs-studieretning-frontpage) {
    #vrtx-main-content,
    #vrtx-main-user {
      > blockquote.blockquote-small,
      .vrtx-introduction-image.medium-intro-image,
      > *:not(h1):not(#responsive-submenu):not(#vrtx-introduction-wrapper):not(.vrtx-introduction):not(.vrtx-introduction-image):not(#vrtx-main-user):not(blockquote):not(.vrtx-comments):not(.info-fullwidth-wide):not(.vrtx-byline):not(.blog-title) {
        margin-left: ~"calc(calc(calc(100%) - @{bodytextContentWidth})/2)";
      }

      // Fix for idents not set on sub-lists, typical paragraphs
      >p[style*='margin-left: 40px']  { padding-left: 40px;  }
      >p[style*='margin-left: 80px']  { padding-left: 80px;  }
      >p[style*='margin-left: 120px'] { padding-left: 120px; }
      >p[style*='margin-left: 40px'],
      >p[style*='margin-left: 80px'],
      >p[style*='margin-left: 120px'] {
        margin-left: ~"calc(calc(calc(100%) - @{bodytextContentWidth})/2)" !important;
      }
    }
  }
  body#vrtx-course-description #vrtx-course-content,
  body#vrtx-course-description .vrtx-date-info,
  body#vrtx-semester #vrtx-main-content .vrtx-frontpage-box:not(:first-child):not(#vrtx-semester-resource-links),
  body#vrtx-student-exchange-agreement #vrtx-main-content > *:not(#vrtx-student-exchange-agreement-contact):not(#vrtx-student-exchange-agreement-university-info):not(.vrtx-introduction):not(#vrtx-student-exchange-agreement-info):not(h1) {
    margin-left: 0;
  }
}

@media @desktopSmallBreakpoint {
  // Content
  #main {
    min-width: ~"calc(@{responsiveBreakpointPx} - @{contentGapRight} - @{contentGapLeft})";

    #total-main,
    #right-main {
      padding-left: 0;
      min-width: ~"calc(@{responsiveBreakpointPx} - @{contentGapRight} - @{contentGapLeft} - 15px)";
    }
  }
  #footer-wrapper #footers,
  #head-admin-menu-wrapper #head-admin-menu,
  #head-wrapper #head,
  #marketing-container .row,
  .grid-container.grid-container-top .row,
  .grid-container.uio-info-message.alert .row,
  .info-link-wrapper .info-link,
  .uio-app-line-top {
    min-width: @responsiveBreakpointPx - @contentGapRight - @contentGapLeft;
  }
  #vrtx-fs-studieprogram-frontpage #main,
  #vrtx-fs-studieretning-frontpage #main {
    #vrtx-main-content > div:not(#vrtx-fs-studieprogram-title-intro-links-wrapper):not(#vrtx-fs-studieretning-title-intro-links-wrapper),
    .vrtx-context-message-box .content-wrapper,
    #vrtx-fs-studieprogram-title-intro-links-wrapper > *,
    #vrtx-fs-studieretning-title-intro-links-wrapper > *,
    #vrtx-breadcrumb-wrapper {
      min-width: @responsiveBreakpointPx - @contentGapRight;
      padding-left: @contentGapLeft;
    }
  }
  #vrtx-frontpage.total-main #main {
    .row,
    .vrtx-back,
    .vrtx-context-box,
    h1,
    #breadcrumbs,
    #vrtx-frontpage-introduction {
      min-width: @responsiveBreakpointPx - @contentGapRight;
      padding-left: @contentGapLeft;
    }
  }
}

// Break 200px before regular responsive breakpoint for person search with right column
@media only screen and (max-width: (@responsiveBreakpointPx + 200px)) {
  #vrtx-personsearch {
    #vrtx-content {
      #vrtx-main-content,
      #vrtx-additional-content {
        float: none;
        width: auto;
        padding-right: 0;
      }
    }
  }
}

// Break 100px before regular responsive breakpoint for content with right column
@media only screen and (max-width: (@responsiveBreakpointPx + 100px)) {
  #vrtx-program-frontpage,
  #vrtx-program-option,
  #vrtx-semester,
  #vrtx-course-description,
  #vrtx-unitsearch,
  #vrtx-searchview,
  #vrtx-event-listing,
  #vrtx-event-listing.datepicker-only-right-column,
  #vrtx-article-listing,
  #vrtx-personsearch,
  #vrtx-personal-data,
  #vrtx-helseforsk,
  #vrtx-structured-project,
  #vrtx-organizational-unit,
  #vrtx-person {
    #vrtx-calendar-listing,
    #vrtx-content {
      #vrtx-main-content,
      #vrtx-additional-content {
        float: none;
        width: auto;
        padding-right: 0;
      }
    }
  }
}

@media @responsiveBreakpoint {

  #left-main,
  #head-breadcrumb-container,
  #breadcrumb-container,
  .responsive-hide {
    display: none;
  }

  // Header, footer, etc
  #marketing-container .row,
  #marketing-customize-container .row,
  .info-link-wrapper .info-link, // Cookie banner
  .uio-app-line-top,  // not in use in new design at the moment
  #head-wrapper #head,
  #head-admin-menu-wrapper #head-admin-menu,
  .grid-container.grid-container-top .row,
  .grid-container.uio-info-message.alert .row, // Alert banner
  #footer-wrapper #footers {
    max-width: none;
    min-width: 0;
    margin: 0;

    padding-left: 15px;
    padding-right: 15px;
  }

  .info-link-wrapper {
    padding-left: 15px;
    padding-right: 15px;

    .info-link {
      padding: 0;
    }
  }

  // Content

  #main {
    max-width: none;
    min-width: 0;
    min-height: auto !important;  // if set by JS for when sidebar is longer than content
    padding-right: 0;
    margin: 0;
    position: static !important; // Not sure why, but KHM get this set with JS to relative in responsive (cant find anything in uio2.js)

    #total-main,
    #right-main {
      padding-left: 0;
      max-width: none;
      min-width: 0;
    }
  }

  // Frontpage content

  #vrtx-fs-studieprogram-frontpage #main,
  #vrtx-fs-studieretning-frontpage #main {
    #vrtx-main-content > div:not(#vrtx-fs-studieprogram-title-intro-links-wrapper):not(#vrtx-fs-studieretning-title-intro-links-wrapper),
    .vrtx-context-message-box .content-wrapper,
    #vrtx-fs-studieprogram-title-intro-links-wrapper > *,
    #vrtx-fs-studieretning-title-intro-links-wrapper > *,
    #vrtx-breadcrumb-wrapper {
      max-width: none;
      min-width: 0;
      padding-right: 15px;
      padding-left: 15px;
      margin-left: 0;
      margin-right: 0;
    }
  }

  #vrtx-fs-studieprogram-frontpage #main,
  #vrtx-fs-studieretning-frontpage #main {
    padding-top: 0;

    #breadcrumbs {
      padding-top: 15px;
    }
  }

  #vrtx-frontpage.total-main #main {
    .row,
    .vrtx-back,
    .vrtx-context-box,
    h1,
    #breadcrumbs,
    #vrtx-frontpage-introduction {
      max-width: none;
      min-width: 0;
      padding-right: 0;
      padding-left: 0;
      margin-left: 0;
      margin-right: 0;
    }

    .vrtx-back,
    .vrtx-context-box,
    h1,
    #breadcrumbs {
      margin-left: 15px;
      margin-right: 15px;
    }

    #vrtx-frontpage-introduction {
      margin-left: 0;
      margin-right: 0;
      padding-left: 15px;
      padding-right: 15px;
    }

    .grid-container {
      width: auto;
      margin-left: 0;
    }
  }

  // 690px inner content, article, event etc
  body:not(#vrtx-frontpage):not(#vrtx-event-listing):not(#vrtx-article-listing):not(#vrtx-searchview):not(#vrtx-unitsearch):not(#vrtx-personsearch):not(#vrtx-personal-data):not(#vrtx-helseforsk):not(#vrtx-semester):not(#vrtx-program-frontpage):not(#vrtx-program-option):not(#vrtx-structured-project):not(#vrtx-structured-project-two):not(#vrtx-person):not(#vrtx-course-description):not(#vrtx-student-exchange-agreement):not(#vrtx-organizational-unit):not(#vrtx-unit-person-listing):not(#vrtx-unit):not(#vrtx-contact-supervisor):not(#vrtx-fs-studieprogram-frontpage):not(#vrtx-fs-studieretning-frontpage) {
    #vrtx-main-content,
    #vrtx-main-user {
      > blockquote.blockquote-small,
      > *:not(h1):not(#vrtx-introduction-wrapper):not(.vrtx-introduction):not(.small-intro-image):not(#vrtx-main-user):not(blockquote):not(.vrtx-comments):not(.info-fullwidth-wide):not(.vrtx-byline) {
        max-width: none;
        margin-left: 0;
        margin-right: 0;
      }
      >p[style*='margin-left: 40px']  { padding-left: 40px;  }
      >p[style*='margin-left: 80px']  { padding-left: 80px;  }
      >p[style*='margin-left: 120px'] { padding-left: 120px; }
      >p[style*='margin-left: 40px'],
      >p[style*='margin-left: 80px'],
      >p[style*='margin-left: 120px'] {
        margin-left: 0 !important;
      }
    }
    &.vortex-test\.uio\.no {
      #vrtx-main-content,
      #vrtx-main-user {
        > blockquote:not(.blockquote-large) {
          max-width: none;
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }

  // Some part of course description and semester shall be narrower and centered
  body#vrtx-course-description #vrtx-course-content,
  body#vrtx-course-description .vrtx-date-info,
  body#vrtx-semester #vrtx-main-content .vrtx-frontpage-box:not(:first-child):not(#vrtx-semester-resource-links),
  body#vrtx-student-exchange-agreement #vrtx-main-content > *:not(#vrtx-student-exchange-agreement-contact):not(#vrtx-student-exchange-agreement-university-info):not(.vrtx-introduction):not(#vrtx-student-exchange-agreement-info):not(h1) {
    max-width: none;
    margin-left: 0;
    margin-right: 0;
  }
}
