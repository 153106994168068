/* Feed with images and date component */

@feedImageScaleOnHover: 1.05;

.vrtx-feed,
.vrtx-event-component {
  max-width: 1000px;
}

.vrtx-feed {
  margin: 20px 0;

  &.with-images {
    ul li {
      grid-template-columns: auto 1fr;

      a.vrtx-image:first-child {
        overflow: hidden;
        display: block;
        width: 230px;
        margin-top: 7px;

        img {
          display: block;
          width: auto;
          max-width: 100%;
        }
      }
    }
  }

  ul {
    margin: 0;
    display: grid;
    gap: 40px;

    li {
      &:before {
        display: none;
      }
      margin: 0;
      display: grid;
      gap: 0 40px;

      .published-date {
        .mixin-small;
        color: @publishedDateGrey;
        display: block;
        margin-bottom: 15px;
      }

      .item-title {
        .mixin-h3;
        color: @textColor;
        margin-bottom: 10px;

        .textUnderlineSwitch;
      }

      .item-description {
        >*:last-child {
          margin-bottom: 0;
        }
      }
      .vrtx-list-item-content > * {
        display: block;

        &:last-child {
          margin-bottom: 0;
        }
      }
      .vrtx-list-item-content > .channel {
        display: inline;
        margin-left: 0;
        .mixin-tiny;
      }
    }
  }

  a.feed-title {
    display: inline-block;
    .mixin-h2;
    margin-bottom: 40px;
  }

  a.all-messages {
    display: inline-block;
    margin-top: 40px;
    .mixin-h4;
  }
}

div:has(.vrtx-event-component) + a.read-more,
div:has(.vrtx-feed) + a.read-more,
div:has(.vrtx-event-component) + p:has(a.read-more),
div:has(.vrtx-feed) + p:has(a.read-more) {
  display: inline-block;
  margin-top: 20px;
  .mixin-h4;
}

#vrtx-daily-events,
.vrtx-event-component {

  .vrtx-daily-events-listing,
  .vrtx-event-component-item {
    width: 100%;

    .vrtx-date-icon,
    .vrtx-daily-events-date {
      display: none;
    }

    .vrtx-daily-event,
    .vrtx-event-component-main {
      .vrtx-resource {
        .resetClearfix();
      }
    }
  }
}

.vrtx-event-component {
  margin: 20px 0;
  overflow: hidden;
  clear: left;
  display: grid;
  gap: 40px;

  &.with-images {
    .vrtx-event-component-main {
      grid-template-columns: auto 1fr;
    }
  }

  .vrtx-event-component-main {
    display: grid;
    gap: 0 40px;
  }

  .vrtx-event-component-item {
    .vrtx-event-component-picture {
      margin: 6px 0 0 0;
      width: 230px;

      a.vrtx-image {
        display: block;
        margin: 0;
        //line-height: 0;
        overflow: hidden;

        img {
          display: block; // removed bottom spacing (previously fixed by line-height)
          width: auto;
          max-width: 100%;
        }
      }
    }

    .vrtx-event-component-title {
      .mixin-h3;
      margin-bottom: 10px;

      a {
        .textUnderlineSwitch;
      }
    }
    .vrtx-event-component-introduction {
      p:last-child {
        margin-bottom: 0;
      }
    }
    .vrtx-event-component-misc {
      .mixin-small;
      color: #333;
      margin-bottom: 15px;

      .vrtx-time-and-place-container {
        abbr {
          text-decoration: none;
        }
      }
    }
  }
}

#main .vrtx-frontpage-box {
  .vrtx-feed.with-images ul li a.vrtx-image:first-child,
  .vrtx-event-component .vrtx-event-component-item .vrtx-event-component-picture {
    width: 220px;
  }
}

#main .left,
#main .right,
#main .thirds-left,
#main .thirds-middle,
#main .thirds-right,
#main .half-box-left,
#main .half-box-right,
#main .two-column-feed,
#main .third-box-left,
#main .third-box-middle,
#main .third-box-right,
#main .three-column-feed {
  .vrtx-event-component {
    .vrtx-list-item-content:only-child {
      display: flex;
      flex-direction: column;

      .vrtx-event-component-title {
        order: 1;
      }
      .vrtx-event-component-misc {
        order: 2;
      }
      .vrtx-event-component-introduction {
        order: 3;
      }
    }
    .vrtx-event-component-item {
      display: flex;
      flex-direction: column;

      .vrtx-event-component-picture {
        order: 1;
      }

      // new container after picture
      .vrtx-list-item-content {
        order: 2;
        display: flex;
        flex-direction: column;
      }
    }
  }
  .vrtx-event-component.with-images .vrtx-event-component-main,
  .vrtx-feed.with-images ul li {
    display: block;

    a.vrtx-image:first-child {
      margin-bottom: 20px;
    }
  }
  .vrtx-feed.with-images ul li a.vrtx-image:first-child,
  .vrtx-event-component .vrtx-event-component-item .vrtx-event-component-picture {
    width: auto;

    img {
      width: auto;
      max-width: ~"min(560px, 100%)";
    }
  }
}

.vrtx-feed:not(.with-images) ul li .item-title,
.vrtx-event-component:not(.with-images) .vrtx-event-component-item .vrtx-event-component-title {
  .mixin-h3;

  // temp check
  .textUnderline(@textColorUnderline);
  &:hover,
  &:focus {
    //color: @linkColor;
    //.textUnderlineHover(@linkColor);
    text-decoration: none;
  }
}

// Multiple column feeds

#main .two-column-feed,
#main .three-column-feed {
  .vrtx-event-component {
    > h2 {
      margin-bottom: -35px;
    }
  }
}

.two-column-feed,
.three-column-feed {
  .vrtx-feed .items,
  .vrtx-event-component {
    display: grid;
  }

  .vrtx-event-component {
    > .vrtx-more,
    > h2 {
      grid-column: 1/-1;
    }
    > .vrtx-more {
      margin-top: 0;
    }
  }

  .vrtx-feed ul:after {
    display: none;
  }
}

.two-column-feed {
  .vrtx-feed .items,
  .vrtx-event-component {
    grid-template-columns: 1fr 1fr;
    gap: 40px 80px;
  }
  .vrtx-feed.with-images .items,
  .vrtx-event-component.with-images {
    gap: 60px 80px;
  }
}

.three-column-feed {
  .vrtx-feed .items,
  .vrtx-event-component {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px 30px;
  }
  .vrtx-feed.with-images .items,
  .vrtx-event-component.with-images {
    gap: 60px 30px;
  }
}

.vrtx-article-body {
  .two-column-feed {
    .vrtx-feed .items,
    .vrtx-event-component {
      gap: 40px 40px;
    }
    .vrtx-feed.with-images .items,
    .vrtx-event-component.with-images {
      gap: 40px 40px;
    }
  }
}

// Featured

#main .featured-feed-item .vrtx-feed.with-images ul li:first-child {
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: 0.9335fr 1fr; // 0.9335fr is thumbnail width 560px (minmax(1fr, 560px) cant be used for this)
  gap: 0 40px;
  align-items: center;

  a.item-title {
    .mixin-h2;
  }

  .vrtx-image {
    img {
      aspect-ratio: 4/3;
      object-fit: cover;
      max-height: none;
    }
  }
}

#main .featured-feed-item .vrtx-feed.with-images ul li:first-child a.vrtx-image:first-child {
  width: auto;
  img {
    width: 100%;
    height: 100%;
  }
}

#vrtx-frontpage #main .featured-feed-item .vrtx-feed li:first-child a.item-title {
  .mixin-h2;
}

#main .featured-feed-item .vrtx-feed li:first-child {
  .vrtx-image {
    img {
      max-height: none;
    }
  }
}

// Missing feed image

#main .two-column-feed,
#main .three-column-feed {
  .vrtx-event-component.with-images .vrtx-event-component-item {
    .vrtx-list-item-content:only-child {
      background: @orange;
      padding: 20px 20px 80px 20px;
    }
  }
}

// Typography / image height in different grid widths

#main .left,
#main .right,
#main .half-box-left,
#main .half-box-right,
#main .two-column-feed {
  .vrtx-feed ul li .vrtx-image,
  .vrtx-event-component .vrtx-event-component-item .vrtx-image {
    img {
      max-height: 315px;
      width: auto;
    }
  }
}

#main .thirds-left,
#main .thirds-middle,
#main .thirds-right,
#main .third-box-left,
#main .third-box-middle,
#main .third-box-right,
#main .three-column-feed {
  .vrtx-feed ul li .vrtx-image,
  .vrtx-event-component .vrtx-event-component-item .vrtx-image {
    img {
      max-height: 214px;
      width: auto;
    }
  }
}

#main .two-column-feed,
#main .three-column-feed {
  .vrtx-feed,
  .vrtx-event-component {
    max-width: none;
  }
}

#vrtx-frontpage {
  a.feed-title {
    .mixin-h2;
  }
  .vrtx-feed ul li .item-title,
  .vrtx-event-component .vrtx-event-component-item .vrtx-event-component-title {
    .mixin-h3;
  }

  #main #vrtx-additional-content,
  #main .third-box-left,
  #main .third-box-middle,
  #main .third-box-right,
  #main .three-column-feed {
    .vrtx-feed ul li .item-title,
    .vrtx-event-component .vrtx-event-component-item .vrtx-event-component-title {
      .mixin-h4;
    }
  }
  .vrtx-feed:not(.with-images) ul li .item-title,
  .vrtx-event-component:not(.with-images) .vrtx-event-component-item .vrtx-event-component-title {
    .mixin-h3;
  }
}
// Content still with right column

#vrtx-unitsearch,
#vrtx-searchview,
#vrtx-personsearch,
#vrtx-personal-data,
#vrtx-helseforsk,
#vrtx-event-listing,
#vrtx-article-listing,
#vrtx-structured-project,
#vrtx-organizational-unit,
#vrtx-person {
  #vrtx-additional-content {
    .vrtx-feed a.feed-title {
      margin-bottom: 20px;
    }
    .vrtx-feed ul li,
    .vrtx-event-component .vrtx-event-component-item {
      .vrtx-event-component-title,
      .item-title {
        .baseFontSize;
      }
    }
  }
}

// Responsive

@media @responsiveBreakpoint {
  #vrtx-daily-events,
  .vrtx-event-component {
    &:not(.with-images) {
      .vrtx-daily-events-listing,
      .vrtx-event-component-item {
        padding-bottom: 25px;
        border-bottom: 1px solid #000;

        &:last-child {
          padding-bottom: 0;
          border-bottom: none;
        }
      }
    }
  }

  .two-column-feed,
  .three-column-feed {
    .vrtx-feed .items,
    .vrtx-event-component,
    .vrtx-event-component.with-images,
    .vrtx-feed.with-images .items {
      gap: 40px 0;
      grid-template-columns: 1fr;
    }
  }

  #main .featured-feed-item .vrtx-feed.with-images ul li:first-child {
    grid-template-columns: 1fr;
  }

  #main .three-column-feed .vrtx-event-component>h2,
  #main .two-column-feed .vrtx-event-component>h2 {
    margin-bottom: 25px;
  }

  .vrtx-feed:not(.with-images) {
    li:nth-child(n) {
      padding-bottom: 25px;
      border-bottom: 1px solid #000;

      &:last-child {
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }

  body,
  body #main,
  #main .vrtx-frontpage-box {
    .vrtx-feed.with-images ul li,
    .vrtx-event-component .vrtx-event-component-main {
      display: block;

      a.vrtx-image:first-child {
        margin-bottom: 20px;
        width: auto;
      }
    }
  }

  #main .left,
  #main .right,
  #main .thirds-left,
  #main .thirds-middle,
  #main .thirds-right,
  #main .vrtx-frontpage-box,
  #main .third-box-left,
  #main .third-box-middle,
  #main .third-box-right,
  #main .half-box-left,
  #main .half-box-right,
  #main .two-column-feed,
  #main .three-column-feed {
    .vrtx-feed ul li .vrtx-image,
    .vrtx-event-component .vrtx-event-component-item .vrtx-image {
      img {
        max-height: none;
        width: 100%;
      }
    }
  }
}
