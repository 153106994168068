@import "modal.less";
@import "variables.less";

// global
html.travel-report-page {
  -webkit-text-size-adjust: 100%; // prevent iOS text size change in landscape orientation
}

// travel report list and modal common styles
#travel-report-list,
#travel-report-modal {
  * {
    letter-spacing: normal !important;
  }
  // hide things except for screen-readers
  .invisible {
    position: absolute;
    left: -100000px;
  }
}

// report-list + category-list shared styles
#travel-report-list,
#travel-report-modal .category-list {
  @media @screen-medium {
    display: grid;
    grid-template-columns: 50% 50%;
  }
  > li {
    margin: 0;
    @media @screen-medium {
      padding: 5rem 4rem;
      &:nth-child(4n+0),
      &:nth-child(4n+1) {
        background-color: var(--colorBgPrimaryTravelReports);
      }
    }
    @media @screen-below-medium {
      padding: 5rem 5%;
      &:nth-child(odd) {
        background-color: var(--colorBgPrimaryTravelReports);
      }
    }
    &::before {
      display: none;
    }
  }
}

// report-list + full report shared styles
#travel-report-list,
#travel-report {
  h3,
  h4 {
    font-weight: bold;
  }
  .student-name {
    line-height: 1.2;
  }
  blockquote {
    font-family: inherit;
    font-style: italic;
    font-size: 2.2rem;
    line-height: 1.5;
    padding: 0;
  }
  p {
    margin: 0;
  }
  .student-attribute {
    @media @screen-medium {
      margin-bottom: 3rem;
    }
    @media @screen-below-medium {
      margin-bottom: 2rem;
    }
    strong,
    span {
      display: block;
    }
  }
}

// uio style2 insane overrides
body#vrtx-student-exchange-agreement {
  #main #right-main #vrtx-content #vrtx-main-content {
    h2.reports-from-students {
      margin-left: 0;
      margin-bottom: 2rem;
    }
    .vrtx-frontpage-box {
      &.travel-reports,
      &.travel-reports-delivery,
      &.no-reports-found {
        margin-left: 0;
        max-width: none;
      }
    }
  }
}

// social components on list page. different spacing due to html structure
#vrtx-student-exchange-agreement .vrtx-frontpage-box.vrtx-social-components {
  margin: 60px 0 0 0;
}

// travel-report-list
#travel-report-list {
  margin: 0;
  > li {
    position: relative;
    .top-region {
      .student-name {
        font-size: 2.6rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 1rem 9rem 3rem 0; // space for score on right
      }
    }
    blockquote {
      margin: 0 0 3rem 0;
      @media @screen-medium {
        max-width: 40rem;
      }
      &::before {
        content: "«";
      }
      &::after {
        content: "»";
      }
    }
    // student data (programme + exchange period)
    .student-data {
      .student-exchange-period {
        display: none;
      }
    }
    .score {
      white-space: nowrap;
      position: absolute;
      top: 4.3rem;
      @media @screen-medium {
        right: 3rem;
      }
      @media @screen-below-medium {
        right: 5%;
      }
      .score-title {
        position: absolute;
        bottom: -2rem;
        right: 0;
        font-size: 1.4rem;
        font-weight: normal;
        text-transform: uppercase;
        span {
          display: none; // strip sentence
        }
      }
      .score-number {
        strong {
          font-size: 8rem;
        }
        span {
          font-size: 1.4rem;
          &:last-child {
            margin-left: .2em;
          }
        }
      }
      .score-elaboration {
        display: none;
      }
    }
    .reason-for-choosing {
      display: none;
    }
    .launch-modal {
      border: none;
      padding: 0;
      font-size: inherit;
      line-height: inherit;
      padding-left: 3rem;
      &::after {
        left: 0;
        top: .45em;
      }
      &:focus,
      &:hover {
        background: transparent;
        color: inherit;
      }
      &:hover {
        text-decoration: underline;
        text-underline-offset: .2em;
      }
    }
    .category-list {
      display: none;
    }
  }
}

// show-more-reports button
.travel-reports {
  .show-all-reports {
    width: auto;
    padding-right: 19px;
    &::after {
      display: none;
    }
    &:focus,
    &:hover {
      padding-left: 19px;
    }
  }
}

// travel-report full
#travel-report {
  margin-bottom: 10rem;
  .top-region {
    display: grid;
    @media @screen-medium {
      grid-template-columns: auto 55%;
      grid-template-rows: auto 1fr;
      margin-bottom: 6rem;
    }
    @media @screen-below-medium {
      margin-bottom: 3rem;
    }
    .student-name {
      font-size: 3rem;
      @media @screen-medium {
        margin: 0 2rem 3rem 0;
      }
      @media @screen-below-medium {
        margin: 0 0 2rem 0;
      }
    }
  }
  blockquote {
    position: relative;
    padding-left: 6rem;
    @media @screen-large {
      font-size: 2.6rem;
      max-width: 50rem;
    }
    @media @screen-medium {
      grid-column-start: 2;
      grid-row-start: 1;
      grid-row-end: 3;
      margin: 5.8rem 0 3rem 0;
    }
    @media @screen-below-medium {
      grid-row-start: 1;
      margin: 0 0 6rem 0;
    }
    &::before {
      content: "";
      display: block;
      background-image: url("data:image/svg+xml,%3Csvg width='63' height='50' viewBox='0 0 63 50' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.0410156 31.0684C0.0410156 22.1035 2.15039 15.1309 6.36914 10.1504C10.6465 5.11133 16.7402 1.80078 24.6504 0.21875V9.35938C18.6152 11.1172 14.6016 14.3984 12.6094 19.2031C11.4961 21.7812 11.0566 24.2129 11.291 26.498H25.002V49.6133H0.0410156V31.0684ZM37.459 31.0684C37.459 22.1035 39.5684 15.1309 43.7871 10.1504C48.0645 5.11133 54.1582 1.80078 62.0684 0.21875V9.35938C56.0332 11.1172 52.0195 14.3984 50.0273 19.2031C48.9141 21.7812 48.4746 24.2129 48.709 26.498H62.4199V49.6133H37.459V31.0684Z' fill='%23FB6666'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: contain;
      height: 6rem;
      position: absolute;
      left: 0;
      @media @screen-medium {
        top: -7.6rem;
        width: 6rem;
      }
      @media @screen-below-medium {
        top: -.8rem;
        width: 4rem;
      }
    }
  }
  .wide-box {
    margin-bottom: 3rem;
    .wide-box-title {
      display: block; // won't affect grid
      font-size: 2.6rem;
      line-height: 1.2;
      margin-bottom: 1rem;
    }
    p {
      max-width: 65rem;
    }
  }
  .score {
    @media @screen-medium {
      display: grid;
      grid-template-columns: 25% auto;
      grid-template-rows: auto 1fr;
      column-gap: 5%;
      row-gap: 1rem;
    }
    @media @screen-below-medium {
      &::after {
        content: "";
        display: block;
        clear: both; // clear score number float
      }
    }
    .score-title {
      @media @screen-medium {
        margin: 0;
      }
    }
    .score-number {
      justify-content: flex-end;
      background: #FFFEA7;
      line-height: 1;
      @media @screen-medium {
        grid-column-start: 1;
        grid-row-start: 1;
        grid-row-end: 3;
        display: flex;
        align-items: center;
        padding: 3rem 20%;
        margin-left: -4rem; // bleed out of left padding
      }
      @media @screen-below-medium {
        float: left;
        padding: .8rem 1rem .8rem 5vw;
        margin: .5rem 2rem .5rem -5vw; // bleed left
      }
      strong {
        @media @screen-medium {
          font-size: 10rem;
        }
        @media @screen-below-medium {
          font-size: 8rem;
        }
      }
      span {
        &:last-child {
          margin-left: .4em;
        }
        @media @screen-medium {
          margin-top: 5.6rem;
        }
        @media @screen-below-medium {
          font-size: 1.4rem;
          margin-top: 4.6rem;
        }
      }
    }
    .score-elaboration {
      @media @screen-medium {
        grid-column-start: 2;
      }
      @media @screen-below-medium {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }
  }
  .reason-for-choosing {
    @media @screen-medium {
      margin-left: 30%;
    }
  }
  .category-list {
    @media @screen-medium {
      margin: 10rem -4rem 0; // bleed horizontally
    }
    @media @screen-below-medium {
      margin: 10rem -5vw 0; // bleed horizontally
    }
    > li {
      background-repeat: no-repeat;
      @media @screen-large {
        background-size: 7rem auto;
        background-position: top 2.5rem right 2.5rem;
      }
      @media @screen-below-large {
        background-size: 6rem auto;
        background-position: top 2.5rem right 5%;
      }
      @media @screen-below-medium {
        padding: 4rem 5% 2rem;
      }
    }
    h4 {
      font-size: 2.6rem;
      line-height: 1.2;
      margin: 0 7rem 3rem 0; // right space for illustration
    }
    h5 {
      font-size: inherit;
      line-height: inherit;
      margin: 0;
    }
    p {
      margin-bottom: 2rem;
    }
    .academic {
      background-image: url("data:image/svg+xml,%3Csvg width='80' height='80' viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='0.3'%3E%3Cpath d='M40.0006 10L3.33398 30L16.6673 37.2667V57.2667L40.0006 70L63.334 57.2667V37.2667L70.0006 33.6333V56.6667H76.6673V30L40.0006 10ZM56.6673 53.3L40.0006 62.4L23.334 53.3V40.9L40.0006 50L56.6673 40.9V53.3ZM40.0006 42.4L17.2673 30L40.0006 17.6L62.734 30L40.0006 42.4Z' fill='%23FB6666'/%3E%3C/g%3E%3C/svg%3E%0A");
    }
    .subjects {
      background-image: url("data:image/svg+xml,%3Csvg width='80' height='80' viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='0.3'%3E%3Cpath d='M73.3327 23.3329H43.3327V29.9996H73.3327V23.3329ZM73.3327 49.9996H43.3327V56.6663H73.3327V49.9996ZM18.466 36.6663L6.66602 24.8663L11.366 20.1663L18.4327 27.2329L32.566 13.0996L37.266 17.7996L18.466 36.6663ZM18.466 63.3329L6.66602 51.5329L11.366 46.8329L18.4327 53.8996L32.566 39.7663L37.266 44.4663L18.466 63.3329Z' fill='%23FB6666'/%3E%3C/g%3E%3C/svg%3E%0A");
      ul {
        margin-bottom: 2rem;
        li {
          margin-bottom: 1.5rem;
        }
      }
    }
    .practical {
      background-image: url("data:image/svg+xml,%3Csvg width='80' height='80' viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='0.3'%3E%3Cpath d='M60.0006 20.0003H53.334C53.334 12.6337 47.3673 6.66699 40.0006 6.66699C32.634 6.66699 26.6673 12.6337 26.6673 20.0003H20.0007C16.334 20.0003 13.334 23.0003 13.334 26.667V66.667C13.334 70.3337 16.334 73.3337 20.0007 73.3337H60.0006C63.6673 73.3337 66.6673 70.3337 66.6673 66.667V26.667C66.6673 23.0003 63.6673 20.0003 60.0006 20.0003ZM40.0006 13.3337C43.6673 13.3337 46.6673 16.3337 46.6673 20.0003H33.334C33.334 16.3337 36.334 13.3337 40.0006 13.3337ZM60.0006 66.667H20.0007V26.667H26.6673V33.3337C26.6673 35.167 28.1673 36.667 30.0006 36.667C31.834 36.667 33.334 35.167 33.334 33.3337V26.667H46.6673V33.3337C46.6673 35.167 48.1673 36.667 50.0006 36.667C51.834 36.667 53.334 35.167 53.334 33.3337V26.667H60.0006V66.667Z' fill='%23FB6666'/%3E%3C/g%3E%3C/svg%3E%0A");
    }
    .economics {
      background-image: url("data:image/svg+xml,%3Csvg width='80' height='80' viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='0.3'%3E%3Cpath d='M49.9993 33.3337C49.9993 31.5003 51.4993 30.0003 53.3327 30.0003C55.166 30.0003 56.666 31.5003 56.666 33.3337C56.666 35.167 55.166 36.667 53.3327 36.667C51.4993 36.667 49.9993 35.167 49.9993 33.3337ZM26.666 30.0003H43.3327V23.3337H26.666V30.0003ZM73.3327 25.0003V48.2337L63.9327 51.367L58.3327 70.0003H39.9993V63.3337H33.3327V70.0003H14.9993C14.9993 70.0003 6.66602 41.8003 6.66602 31.667C6.66602 21.5337 14.866 13.3337 24.9993 13.3337H41.666C44.6993 9.30033 49.5327 6.66699 54.9993 6.66699C57.766 6.66699 59.9993 8.90033 59.9993 11.667C59.9993 12.367 59.866 13.0003 59.5993 13.6003C59.1327 14.7337 58.7327 16.0337 58.5327 17.4337L66.0993 25.0003H73.3327ZM66.666 31.667H63.3327L51.666 20.0003C51.666 17.8337 51.966 15.7003 52.5327 13.6337C49.2993 14.467 46.666 16.867 45.566 20.0003H24.9993C18.566 20.0003 13.3327 25.2337 13.3327 31.667C13.3327 37.9337 17.3993 53.8337 20.0327 63.3337H26.666V56.667H46.666V63.3337H53.366L58.5327 46.167L66.666 43.4337V31.667Z' fill='%23FB6666'/%3E%3C/g%3E%3C/svg%3E%0A");
      .financial-support {
        margin-bottom: 2rem;
        ul {
          margin: 0;
          li {
            display: inline-block;
            padding-left: 30px;
            margin: 0 1rem 0 0;
            &::before {
              content: "";
              width: 24px;
              height: 24px;
              left: 0;
              top: 0;
              margin: 0;
            }
            &.no {
              text-decoration: line-through;
              &::before {
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                opacity: .3;
              }
            }
            &.yes {
              &::before {
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 11L12 14L22 4' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M21 12V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H16' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
              }
            }
          }
        }
        .other {
          margin: 0;
          strong {
            font-weight: normal;
          }
        }
      }
    }
    .social {
      background-image: url("data:image/svg+xml,%3Csvg width='80' height='80' viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='0.3'%3E%3Cpath d='M23.434 16.4997L11.634 28.2997C0.567318 39.3663 0.567318 57.2997 11.634 68.3663C22.7006 79.433 40.634 79.433 51.7006 68.3663L71.734 48.333C74.9673 45.0997 74.9673 39.7997 71.734 36.533C71.334 36.133 70.9006 35.7663 70.434 35.4663L71.734 34.1663C74.9673 30.933 74.9673 25.633 71.734 22.3663C71.2006 21.833 70.5673 21.3663 69.934 20.9997C71.2673 17.933 70.7006 14.2663 68.2006 11.7663C65.3006 8.86634 60.8006 8.56634 57.534 10.833C57.2006 10.333 56.834 9.86634 56.4006 9.43301C53.1673 6.19967 47.8673 6.19967 44.6006 9.43301L36.234 17.7997C35.934 17.333 35.5673 16.8997 35.1673 16.4997C31.934 13.2663 26.6673 13.2663 23.434 16.4997ZM28.134 21.233C28.8006 20.5663 29.834 20.5663 30.5006 21.233C31.1673 21.8997 31.1673 22.933 30.5006 23.5997L19.9006 34.1997C23.8006 38.0997 23.8006 44.433 19.9006 48.333L24.6006 53.033C29.434 48.1997 30.6673 41.133 28.334 35.1663L49.334 14.1663C50.0006 13.4997 51.034 13.4997 51.7006 14.1663C52.3673 14.833 52.3673 15.8663 51.7006 16.533L36.3673 31.8663L41.0673 36.5663L61.1006 16.533C61.7673 15.8663 62.8006 15.8663 63.4673 16.533C64.134 17.1997 64.134 18.233 63.4673 18.8997L43.434 38.933L48.134 43.633L64.634 27.133C65.3006 26.4663 66.334 26.4663 67.0006 27.133C67.6673 27.7997 67.6673 28.833 67.0006 29.4997L48.134 48.3663L52.834 53.0663L64.634 41.2663C65.3006 40.5997 66.334 40.5997 67.0006 41.2663C67.6673 41.933 67.6673 42.9663 67.0006 43.633L47.0006 63.6663C38.534 72.133 24.834 72.133 16.3673 63.6663C7.90065 55.1997 7.90065 41.4997 16.3673 33.033L28.134 21.233ZM76.6673 56.6663C76.6673 67.6997 67.7006 76.6663 56.6673 76.6663V71.6663C64.934 71.6663 71.6673 64.933 71.6673 56.6663H76.6673ZM3.33398 23.333C3.33398 12.2997 12.3007 3.33301 23.334 3.33301V8.33301C15.0673 8.33301 8.33398 15.0663 8.33398 23.333H3.33398Z' fill='%23FB6666'/%3E%3C/g%3E%3C/svg%3E%0A");
    }
    .useful-tips {
      background-image: url("data:image/svg+xml,%3Csvg width='80' height='80' viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='0.3'%3E%3Cpath d='M40.0006 73.3337C43.6673 73.3337 46.6673 70.3337 46.6673 66.667H33.334C33.334 70.3337 36.334 73.3337 40.0006 73.3337Z' fill='%23FB6666'/%3E%3Cpath d='M53.3327 56.667H26.666V63.3338H53.3327V56.667Z' fill='%23FB6666'/%3E%3Cpath d='M40 6.66699C26.2 6.66699 15 17.867 15 31.667C15 44.4003 23.8667 51.2004 27.5667 53.3337H52.4333C56.1333 51.2004 65 44.4003 65 31.667C65 17.867 53.8 6.66699 40 6.66699ZM50.5 46.667H29.5C26.6333 44.6337 21.6667 39.9003 21.6667 31.667C21.6667 21.567 29.9 13.3337 40 13.3337C50.1 13.3337 58.3333 21.567 58.3333 31.667C58.3333 39.9003 53.3667 44.6337 50.5 46.667Z' fill='%23FB6666'/%3E%3C/g%3E%3C/svg%3E%0A");
    }
    // category-list item height limit
    > li {
      .height-wrapper {
        max-height: 500px;
        overflow: hidden;
      }
      .show-more-button {
        width: auto;
        padding: 1rem;
        border: none;
        font-size: inherit;
        font-weight: bold;
        &::after {
          display: none;
        }
        &::before {
          content: "+";
          position: relative;
          left: -1rem;
        }
      }
      &.has-overflow {
        .height-wrapper {
          border-bottom: 1px dashed rgba(0,0,0, .5);
        }
      }
      &.expanded,
      &.expanded-neighbour {
        .height-wrapper {
          max-height: none;
          border: none;
        }
        .show-more-button {
          display: none;
        }
      }
    }
  }
}
